import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController,
    private router: Router,
    private translateService: TranslateService
  ) { }

  showErrorAlert(mes: any) {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        header: this.translateService.instant('ALERT.errorHeader'),
        message: mes,
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }


  showSuccessAlert(mes: any) {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        header: this.translateService.instant('ALERT.successHeader'),
        message: mes,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.router.navigate['core/member/transaction/options'];
            }
          },
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }
}
